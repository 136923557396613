import { lazy } from "react";

const surveyDownloadRoute = {
  path: "/admin/survey/download",
  component: lazy(() => import("view/SurveyDownload")),
  title: "SurveyDownload",
  protected: false,
};

export default surveyDownloadRoute;
