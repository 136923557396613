import { Box } from "@chakra-ui/react";
import "@fontsource/red-hat-display/900.css";
import PageLoader from "components/PageLoader";
import { LayoutContext } from "context/layout";
import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "routes";
import "./assets/styles/index.scss";
import Footer from "./components/Footer";
import WithSubnavigation from "./components/Navigation";
import useHotjar from 'react-use-hotjar';

function App() {
  const { showHeader, showFooter, setShowHeader, setShowFooter } =
    useContext(LayoutContext);

  const location = useLocation();
  const { initHotjar } = useHotjar();

  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    if (env === 'PRODUCTION') {
      const websiteId = 3878380;
      const hotjarVersion = 6;
      initHotjar(websiteId, hotjarVersion, false);
    }

  }, [initHotjar]);

  useEffect(() => {
    if (location.pathname.includes("survey")) {
      setShowFooter(false);
      setShowHeader(false);
    } else {
      setShowFooter(true);
      setShowHeader(true);
    }
  }, [setShowFooter, setShowHeader, location]);

  return (
    <Box minHeight="100vh">
      {showHeader && <WithSubnavigation />}

      <React.Suspense fallback={<PageLoader />}>
        <Routes>
          {routes.map(({ path, title, component: Component }) => {
            return (
              <Route
                path={path}
                element={<Component title={title} />}
                key={path}
              />
            );
          })}
        </Routes>
      </React.Suspense>
      {showFooter && <Footer />}
    </Box>
  );
}

export default App;
