import { useState } from "react";
import { LayoutContext } from ".";

const LayoutProvider = ({ children }) => {
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  return (
    <LayoutContext.Provider
      value={{
        showFooter,
        showHeader,
        setShowFooter,
        setShowHeader,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
