import {extendTheme} from "@chakra-ui/react";

const theme = extendTheme({
    fonts: {
        heading: "Red Hat Display, sans-serif",
        body: "'DM Sans', sans-serif",
    },
    breakpoints: {
        sm: '30em',
        md: '768px',
        lg: '1024px',
        xl: '1440px',
        '2xl': '1640px',
        '3xl': '1880px',
    },
    components: {
        Radio: {
            parts: ["label"],
            baseStyle: {
                label: {
                    width: "100%",
                    margin: "none",
                },
            },
        },
    },
});

export default theme;
