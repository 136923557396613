import surveyDownloadRoute from "./surveyDownload";
import ANFMainRoute from "./anfMain";
import downloadDataRoute from "./downloadData";
import homeRoute from "./home";
import policyRoute from "./privacyPolicy";
import surveyRoute from "./survey";
import stawiRoute from "./stawiSpaces"
import stawiHelpCenterRoute from "./stawiHelpCenter"
import subSectorResults from "./subSectorResults";

const routes = [
    homeRoute,
    policyRoute,
    ANFMainRoute,
    surveyRoute,
    downloadDataRoute,
    surveyDownloadRoute,
    stawiRoute,
    stawiHelpCenterRoute,
    subSectorResults
];

export default routes;
