import { lazy } from "react";

const homeRoute = {
  path: "/",
  component: lazy(() => import("view")),
  title: "Creative Economy Practice",
  protected: false,
};

export default homeRoute;
