import { lazy } from "react";

const policyRoute = {
  path: "/policy",
  component: lazy(() => import("view/Policy")),
  title: "Creative Economy Practice",
  protected: false,
};

export default policyRoute;
