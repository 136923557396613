import { lazy } from "react";

const StawiSpaces = {
  path: "/stawi-spaces",
  component: lazy(() => import("view/StawiSpaces")),
  title: "Stawi Spaces",
  protected: false,
};

export default StawiSpaces;
