export const COLORS = {
  primary_green: "#7BC042",
  primary_orange: "#E36E1E",
  black: "#1F1F1F",
  white: "#FFFFFF",
};

export const SIZES = {
  h1: 93,
  h2: 58,
  h3: 47,
  h4: 33,
  h5: 23,
  h6: 19,
  body_16: 16,
  body_14: 14,
  body_12: 12,
  body_10: 10,
  sm: 2,
  base: 4,
  md: 6,
  lg: 8,
  xl: 12,
  xxl: 16,
  xxxl: 24,
};

export const WEIGHTS = {
  100: "Inter-Thin",
  200: "Inter-ExtraLight",
  300: "Inter-Light",
  400: "Inter-Regular",
  500: "Inter-Medium",
  600: "Inter-SemiBold",
  700: "Inter-Bold",
  800: "Inter-ExtraBold",
  900: "Inter-Black",
};

export const FONTS = {
  headline_93: {
    fontSize: SIZES.h1,
    fontFamily: WEIGHTS[300],
    lineHeight: 113,
    letterSpacing: -1.5,
  },
  headline_58: {
    fontSize: SIZES.h2,
    fontFamily: WEIGHTS[300],
    lineHeight: 70,
    letterSpacing: -0.5,
  },
  headline_47: {
    fontSize: SIZES.h3,
    fontFamily: WEIGHTS[400],
    lineHeight: 57,
  },
  headline_33: {
    fontSize: SIZES.h4,
    fontFamily: WEIGHTS[400],
    lineHeight: 40,
    letterSpacing: 0.25,
  },
  headline_23: {
    fontSize: SIZES.h5,
    fontFamily: WEIGHTS[400],
    lineHeight: 28,
  },
  headline_19: {
    fontSize: SIZES.h6,
    fontFamily: WEIGHTS[500],
    lineHeight: 28,
    letterSpacing: 0.15,
  },
  body_16: {
    fontSize: SIZES.body_16,
    fontFamily: WEIGHTS[400],
    lineHeight: 24,
    letterSpacing: 0.5,
  },
  body_14: {
    fontSize: SIZES.body_14,
    fontFamily: WEIGHTS[400],
    lineHeight: 20,
    letterSpacing: 0.25,
  },
  subtitle_16: {
    fontSize: SIZES.body_16,
    fontFamily: WEIGHTS[400],
    lineHeight: 22,
    letterSpacing: 0.15,
  },
  subtitle_14: {
    fontSize: SIZES.body_14,
    fontFamily: WEIGHTS[400],
    lineHeight: 22,
    letterSpacing: 0.1,
  },
  buttonText: {
    fontSize: SIZES.body_14,
    fontFamily: WEIGHTS[500],
    letterSpacing: 1.25,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  caption: {
    fontSize: SIZES.body_12,
    fontFamily: WEIGHTS[400],
    letterSpacing: 0.4,
    lineHeight: 16,
  },
  overline_small: {
    fontSize: SIZES.body_10,
    fontFamily: WEIGHTS[400],
    letterSpacing: 1.5,
    lineHeight: 16,
  },
  overline: {
    fontSize: SIZES.body_10,
    fontFamily: WEIGHTS[400],
    letterSpacing: 1.5,
    lineHeight: 16,
    textTransform: "uppercase",
  },
};
