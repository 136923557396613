import axios from "axios";
import { useEffect, useState } from "react";
import TranslationContext from ".";
const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState({ code: "en", label: "ENG" });

  const translate = async (text = "") => {
    if (language.code.toLocaleLowerCase() === "en" || text.trim() === "") {
      return text;
    }

    let url = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyAdx3l7Z8EwXowijr3oTgvhjTYROVxmql4`;
    url += "&q=" + encodeURI(text);
    url += `&source=en`;
    url += `&target=${language.code}`;

    const {
      data: { data },
    } = await axios.get(url);
    return data.translations[0].translatedText;
  };

  useEffect(() => {
    const lang = JSON.parse(localStorage.getItem("language"));
    if (lang) {
      setLanguage(lang);
    }
  }, []);

  useEffect(() => {
    if (language) {
      localStorage.setItem("language", JSON.stringify(language));
    }
  }, [language]);

  return (
    <TranslationContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
