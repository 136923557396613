import { lazy } from "react";

const StawiHelpCenter = {
  path: "/stawi-spaces/help-center",
  component: lazy(() => import("view/StawiHelpCenter")),
  title: "Stawi Spaces Help Center",
  protected: false,
};

export default StawiHelpCenter;
