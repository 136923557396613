import {useEffect, useState} from "react";
import {
    ChevronDownIcon,
    CloseIcon,
    HamburgerIcon,
} from "@chakra-ui/icons";
import {scroller} from "react-scroll";
import {
    Box,
    Collapse,
    Flex,
    Icon,
    IconButton,
    Image,
    Link,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import useTranslation from "hooks/useTranslation";
import {useLocation, Link as ReachLink} from "react-router-dom";
import Logo from "../assets/img/cep-logo.svg";
import useScrollingUp from "../hooks/useScrollingUp";
import Button from "./Button";
import TranslatedText from "./commons/TranslatedText";
import LanguageDropdown from "./LanguageDropdown";

const HASH_MENUS = {
    "#aboutUs": "WhatWeDo",
    "#community": "ExpertCommunity",
    "#programmes": "FindOpportunity",
    "#whatWeDO": "WhatWeDo",
};
export default function WithSubnavigation() {
    const {isOpen, onToggle} = useDisclosure();
    const location = useLocation();

    const [activeMenu, setActiveMenu] = useState("");
    const scrolledUp = useScrollingUp();
    useEffect(() => {
        if (location.hash) setActiveMenu(HASH_MENUS[location.hash || ""]);
        else setActiveMenu("Hero")
    }, [location.hash]);

    useEffect(() => {
        if (activeMenu)
            scroller.scrollTo(activeMenu, {
                duration: 1000,
                delay: 0,
                smooth: "easeInOutCubic",
                offset: activeMenu === "ExpertCommunity" ? -80 : activeMenu === "FindOpportunity" ? 10 : -50,
            });
    }, [activeMenu]);

    return (
        <Box
            width="100%"
            zIndex={200}
            display={scrolledUp ? "block" : "none"}
            position="fixed"
            top={0}
            height={120}
            left={0}
            right={0}
            bottom={0}
            alignContent={"center"}
            justifyItems="center"
            alignItems="center"
            bg={"white"}
            mx="auto"
            style={{
                borderBottom: "1px solid rgba(21, 21, 21, 0.04)",
            }}
        >
            <Box maxW="1440px" marginX={["auto", "auto", "auto"]} py={3}
                 px={{base: "20px", lg: "80px", xl: "140px"}}>
                < Flex minH={"60px"} py={"20px"} align={"center"} alignSelf={"center"}>
                    <Flex flex={{base: 1}} justify={{base: "start", md: "start"}}>
                        <Box width={{sm: 20, md: 110}} flex="1">
                            <ReachLink to="/">
                                <Image src={Logo} alt="CEP Logo"/>
                            </ReachLink>
                        </Box>
                        <Flex
                            mr="28px"
                            display={{base: "none", lg: "flex"}}
                            alignItems="center"
                            columnGap="32px"
                        >
                            <DesktopNav/>
                        </Flex>
                        <Flex alignItems="center" columnGap="20px">
                            <LanguageDropdown/>
                            <a href="http://bit.ly/cepafrica" target="_blank" rel="noreferrer">
                                <Button display={["none", "none", "none", "flex"]} px="23px" variant={"primary"}
                                        _hover={{}}>
                                    {useTranslation("Join the Community")}
                                </Button>
                            </a>
                        </Flex>


                    </Flex>


                    <Flex
                        ml={{base: -2}}
                        display={{base: "flex", lg: "none"}}
                        justifyContent="flex-end"
                    >
                        <IconButton
                            onClick={onToggle}
                            icon={
                                isOpen ? (
                                    <CloseIcon w={6} h={6}/>
                                ) : (
                                    <HamburgerIcon w={6} h={6}/>
                                )
                            }
                            variant={"ghost"}
                            aria-label={"Toggle Navigation"}
                        />
                    </Flex>
                </Flex>

                <Collapse
                    in={isOpen}
                    style={{
                        background: "white",
                        zIndex: 1000,
                        overflow: "overlay !important",
                    }}
                >
                    <MobileNav/>
                </Collapse>
            </Box>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue("#3B3D40", "gray.200");
    return (
        <Stack direction={"row"} spacing={4} whiteSpace={"nowrap"}>
            {NAV_ITEMS.map((navItem, index) => {
                return (
                    <Box m={0} key={navItem.label}>
                        <Link
                            letterSpacing={"0.17px"}
                            href={navItem.href ?? "#"}
                            fontSize={17}
                            fontWeight={400}
                            color={linkColor}
                            target={navItem?.target || '_self'}
                            _hover={{
                                textDecoration: "none",
                            }}
                        >
                            {<TranslatedText>{navItem.label}</TranslatedText>}
                        </Link>
                    </Box>
                )
                    ;
            })}
        </Stack>
    );
};


const MobileNav = () => {
    return (
        <Stack bg={"white"} p={4} mb={5} display={{lg: "none"}}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
            <Flex justify="center">
                <Text
                    as={Link}
                    textAlign="center"
                    fontWeight={600}
                    href="http://bit.ly/cepafrica"
                >
                    {useTranslation("Join the Community")}
                </Text>
            </Flex>
        </Stack>
    );
};

const MobileNavItem = ({label, children, href, target}) => {
    const {isOpen, onToggle} = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? "#"}
                target={target || '_self'}
                justify={"center"}
                align={"center"}
                _hover={{
                    textDecoration: "none",
                }}
            >
                <Text
                    fontWeight={600}
                    color={useColorModeValue("gray.600", "gray.200")}
                >
                    {useTranslation(label)}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={"all .25s ease-in-out"}
                        transform={isOpen ? "rotate(180deg)" : ""}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse
                in={isOpen}
                // animateOpacity
                style={{
                    background: "white",
                    zIndex: 1000,
                    overflow: "overlay !important",
                }}
            >
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={"solid"}
                    borderColor={useColorModeValue("gray.200", "gray.700")}
                    align={"center"}
                    bg={"white"}
                    zIndex={100}
                >
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

const NAV_ITEMS = [
    {
        label: "About Us",
        href: "/#aboutUs",
    },
    {
        label: "Programmes",
        href: "/#programmes",
    },
    {
        label: "Expert Community",
        href: "/#community",
    },
    {
        label: "Blog",
        href: "https://curatedatthecreative.substack.com/",
        target: '_blank'
    },
];
