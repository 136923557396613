import { useState } from "react";

import { SurveyDownloadContext } from ".";

const SurveyDownloadProvider = ({ children }) => {
  const [disableProceed, setDisableProceed] = useState(true);
  const [surveyDownloadForm, setSurveyDownloadForm] = useState({
    username: "",
    password: "",
  });

  return (
    <SurveyDownloadContext.Provider
      value={{
        surveyDownloadForm,
        setSurveyDownloadForm,
        disableProceed,
        setDisableProceed,
      }}
    >
      {children}
    </SurveyDownloadContext.Provider>
  );
};

export default SurveyDownloadProvider;
