import { useCallback, useState } from "react";
import { arrayToPlain } from "utils";
import { SurveyContext } from ".";

const SurveyProvider = ({ children }) => {
  const [disableProceed, setDisableProceed] = useState(true);
  const [surveyForm, setSurveyForm] = useState({
    nameOfOrganization: "",
    email: "",
    name: "",
    roleInOrganization: "",
    city: "",
    address: "",
    apartment: "",
    town: "",
    postalCode: "",
    location: { lat: 0, lng: 0 },
    organizationEstablished: "",
    organizationType: "",
    organizationService: [],
    averageInPersonParticipant2020: "",
    averageInPersonParticipant2021: "",
    numberOfInPersonEvents2020: "",
    numberOfInPersonEvents2021: "",
    numberOfOnlineEvents2020: "",
    averageOnlineEventsParticipant2020: "",
    numberOfOnlineEvents2021: "",
    averageOnlineEventsParticipant2021: "",
    receivedFinancialSupport: "",
    whenDidYouReceiveSupport: [], // year 2017 - 2021
    totalAmountReceived: "",
    listOfTopEvents: "",
    freedomOfCreation: "",
    extentOfArtAndCultureCriticism: "",
    otherTypesOfSupport: [],
    organizationLocationLatitude: 0,
    organizationLocationLongitude: 0,
    numberOfEmployedEmployees: "",
    fundingFromOtherOrganizations: [],
    receivedAmountFromOtherOrganizations: "",
    receivedSupportFromOtherInstitutions: "",
    supportFromOtherInstitutions: "",
    customOrganizationType: "",
  });

  const validateForm = useCallback(() => {
    // dependent fields
    const {
      // optional fields
      apartment,
      postalCode,
      receivedFinancialSupport,
      receivedSupportFromOtherInstitutions,
      organizationType,
      location,
      // optional on a condition
      whenDidYouReceiveSupport,
      supportFromOtherInstitutions,
      customOrganizationType,
      totalAmountReceived,
      organizationService,
      otherTypesOfSupport,
      fundingFromOtherOrganizations,
      ...form
    } = surveyForm;

    // remaining form fields

    // received financial support

    const hasInvalidFields = Object.values({
      ...form,
      receivedFinancialSupport,
      receivedSupportFromOtherInstitutions,
      organizationType,
      otherTypesOfSupport: arrayToPlain(otherTypesOfSupport),
      organizationService: arrayToPlain(organizationService),
      fundingFromOtherOrganizations: arrayToPlain(
        fundingFromOtherOrganizations
      ),
    }).some((value) => {
      return String(value).trim() === "";
    });

    const hasInvalidFinSupportFields =
      (!receivedFinancialSupport ||
        receivedFinancialSupport.toLowerCase() === "yes") &&
      (totalAmountReceived === "" ||
        arrayToPlain(whenDidYouReceiveSupport) === "");

    const hasOtherInvalidFinSupportFields =
      (!receivedSupportFromOtherInstitutions ||
        receivedSupportFromOtherInstitutions.toLowerCase() === "yes") &&
      supportFromOtherInstitutions.trim() === "";

    setDisableProceed(
      hasInvalidFields ||
        hasInvalidFinSupportFields ||
        hasOtherInvalidFinSupportFields
    );
  }, [surveyForm]);

  const valueChangeHandler = ({ target: { name, value } }) => {
    setSurveyForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <SurveyContext.Provider
      value={{
        surveyForm,
        setSurveyForm,
        valueChangeHandler,
        validateForm,
        disableProceed,
        setDisableProceed,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export default SurveyProvider;
