import { useCallback, useEffect, useRef, useState } from "react";
import { off, on } from "../utils";

const useScrollingUp = () => {
  let prevScroll = useRef(null);
  prevScroll.current = window.pageYOffset;
  const [scrollingUp, setScrollingUp] = useState(true);
  const handleScroll = useCallback(() => {
    const currScroll = window.pageYOffset;
    const isScrolled = prevScroll.current > currScroll;
    setScrollingUp(currScroll > 80 ? isScrolled : true);
    prevScroll.current = currScroll;
  }, []);
  useEffect(() => {
    on(window, "scroll", handleScroll, { passive: true });
    return () => {
      off(window, "scroll", handleScroll, { passive: true });
    };
  }, [handleScroll]);
  return scrollingUp;
};

export default useScrollingUp;
