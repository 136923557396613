import TranslationContext from "context/translation";
import React, { useContext, useEffect, useState } from "react";

const useTranslation = (value) => {
  const { translate } = useContext(TranslationContext);
  const [text, setText] = useState(value);

  useEffect(() => {
    (async () => {
      const translatedText = await translate(value);
      setText(translatedText);
    })();

    return () => {
      setText((txt) => txt);
    };
  }, [value, translate]);

  return React.useMemo(
    () =>
      (() => {
        var txt = document.createElement("textarea");
        txt.innerHTML = text;
        return txt.value;
      })(),
    [text]
  );
};
export default useTranslation;
