import { lazy } from "react";

const surveyRoute = {
  path: "/sub-sector-results",
  component: lazy(() => import("view/SubSectorResults")),
  title: "SubSectorResults",
  protected: false,
};

export default surveyRoute;
