import { ChakraProvider } from "@chakra-ui/react";
import LayoutProvider from "context/layout/LayoutProvider";
import SurveyProvider from "context/survey/SurverProvider";
import SurveyDownloadProvider from "context/surveyDownload/SurveyDownloadProvider";
import TranslationProvider from "context/translation/TranslationProvider";
import React from "react";
import ReactDOM from "react-dom";
import "react-dropdown/style.css";
import { BrowserRouter } from "react-router-dom";
import theme from "theme";
import App from "./App";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
ReactDOM.render(
  <React.StrictMode>
    <LayoutProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <TranslationProvider>
            <SurveyProvider>
              <SurveyDownloadProvider>
                <App />
              </SurveyDownloadProvider>
            </SurveyProvider>
          </TranslationProvider>
        </BrowserRouter>
      </ChakraProvider>
    </LayoutProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
