import { Spinner, Stack } from "@chakra-ui/react";
import { COLORS } from "assets/themes";

const PageLoader = () => {
  return (
    <Stack
      align="center"
      minH="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="xl" colorScheme={COLORS.primary_orange}></Spinner>
    </Stack>
  );
};

export default PageLoader;
