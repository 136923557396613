import { lazy } from "react";

const ANFMainRoute = {
  path: "/anf-main",
  component: lazy(() => import("view/ANFMain")),
  title: "ANF Main",
  protected: false,
};

export default ANFMainRoute;
