import ArrowDown from "assets/img/caret-down.svg";
import { LANGUAGES } from "constant/languages";
import TranslationContext from "context/translation";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const LanguageDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { setLanguage, language } = useContext(TranslationContext);

  const className = useMemo(
    () =>
      showDropdown
        ? "lng-dropdown__inner visible"
        : "lng-dropdown__inner hidden",
    [showDropdown]
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  const selectLanguage = (lang) => () => {
    setLanguage(lang);
    setShowDropdown(false);
    navigate({ ...location, search: `lang=${lang.code}&label=${lang.label}` });
  };

  useEffect(() => {
    if (searchParams.get("lang") && searchParams.get("label")) {
      setLanguage({
        code: searchParams.get("lang"),
        label: searchParams.get("label"),
      });
    }
  }, [searchParams, setLanguage]);

  return (
    <div className="lng-dropdown__container">
      <span onClick={toggleDropdown} className="lng-dropdown__trigger">
        <span className="lng-dropdown__trigger--label">{language.label}</span>
        <img
          className="lng-dropdown__trigger--icon"
          src={ArrowDown}
          alt="dropdown arrow"
        />
      </span>

      <div className={className}>
        {LANGUAGES.map((item) => (
          <span
            key={item.code}
            className="lng-dropdown__item"
            onClick={selectLanguage(item)}
          >
            {item.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default LanguageDropdown;
