import {Button as Btn} from "@chakra-ui/react";
import {COLORS} from "assets/themes";

const Button = ({
                    variant = "primary",
                    onClick,
                    isLoading,
                    disabled,
                    children,
                    ...rest
                }) => {
    return (
        <Btn
            color={"#fff"}
            borderRadius={10}
            fontSize={{sm: 14, md: 15}}
            fontFamily={"DM Sans, sans-serif"}
            lineHeight={{sm: 18, md: 20}}
            fontWeight={500}
            border="none"
            px={"34px"}
            py={"14px"}
            height="56px"
            backgroundColor={COLORS.primary_orange}
            onClick={onClick}
            disabled={disabled}
            isLoading={isLoading}
            {...rest}
        >
            {children}
        </Btn>
    );
};

export default Button;
