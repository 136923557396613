export function on(obj, ...args) {
  obj.addEventListener(...args);
}

export function off(obj, ...args) {
  obj.removeEventListener(...args);
}

export const arrayToPlain = (item) => {
  if (Array.isArray(item)) {
    return item.join(",");
  }
  return item;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
