import { lazy } from "react";

const surveyRoute = {
  path: "/survey",
  component: lazy(() => import("view/Survey")),
  title: "Survey",
  protected: false,
};

export default surveyRoute;
