import { lazy } from "react";

const downloadDataRoute = {
  path: "/survey-data",
  component: lazy(() => import("view/SurveyData")),
  title: "Survey data",
  protected: false,
};

export default downloadDataRoute;
